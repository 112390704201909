import { Box, Flex, Grid, Link, Text } from '@chakra-ui/react'
import { ReactElement } from 'react'
import Image from 'next/image'

import ScrollLink from '@/lib/scroll'

import Banner from './Banner'
import { Section, Container } from './styles'
import * as externalLinks from '@/routes/external'
import { SectionProps } from '../../types'

export type SummaryProps = SectionProps

const Summary = (props: SummaryProps): ReactElement => {
  const { id } = props

  return (
    <Section id={id}>
      <Container
        sx={{
          pt: ['80px'],
          pb: ['88px'],
          d: 'flex',
          flexFlow: {
            base: 'column nowrap',
            md: 'row-reverse nowrap',
          },
          '& > *': {
            flex: '1 1 auto',
          },
        }}
      >
        <Box
          layerStyle="landingContainer"
          sx={{
            maxW: {
              base: '553px',
              md: 'unset',
            },
            mx: 'auto',
            ml: {
              base: 'auto',
              md: 16,
            },
            px: 0,
          }}
        >
          <Contact />
        </Box>

        <Box
          layerStyle="landingContainer"
          sx={{
            mx: 'auto',
            ml: 'auto',
            px: 0,
          }}
        >
          <Products />
        </Box>
      </Container>
    </Section>
  )
}

const Contact = (): ReactElement => {
  return (
    <Box
      sx={{
        color: 'white',
        fontWeight: 300,
        textAlign: ['center', 'center', 'start', 'start'],
      }}
    >
      <Box
        sx={{
          w: ['270px', '364px', '312px', '312px'],
          h: ['52px', '70px', '64px', '64px'],
          mx: ['auto', 'auto', 'unset', 'unset'],
          mb: ['14px', '48px', '24px', '24px'],
          maxW: '100%',
          backgroundImage: 'url(/images/logo-white.svg)',
        }}
        layerStyle="background"
      />

      <Text
        sx={{
          mb: ['24px', '32px', '24px', '24px'],
          fontSize: ['16px', '24px', '18px', '18px'],
          br: {
            d: ['none', 'none', 'none', 'block'],
          },
        }}
      >
        1778 อาคารซัมเมอร์ฮับ ออฟฟิศ, ชั้น 6 ถนนสุขุมวิท
        <br />
        แขวงพระโขนง เขตคลองเตย
        <br />
        กรุงเทพมหานคร 10110 ประเทศไทย
      </Text>

      <Box
        sx={{
          mb: ['18px', '32px', '40px', '40px'],
        }}
      >
        <Text
          sx={{
            fontSize: ['16px', '24px', '28px', '28px'],
          }}
        >
          sales@datawow.io
        </Text>
        <Text sx={{ fontSize: ['16px', '24px', '18px', '18px'] }}>
          โทร: 02-024-5560
        </Text>
      </Box>

      <Flex
        sx={{
          alignItems: ['center'],
          flexDir: ['column', 'column', 'row', 'row'],
          justifyContent: ['unset', 'unset', 'space-between', 'space-between'],
          mb: [0, 0, '12px', '12px'],
        }}
      >
        <SocialIcons />

        <Box
          layerStyle="background"
          sx={{
            backgroundImage: 'url(/images/iso.svg)',
            width: ['140px', '140px', '105px'],
            height: ['80px', '80px', '60px'],
            mt: ['24px', '24px', 0],
          }}
        />
      </Flex>

      <Box
        sx={{
          d: ['none', 'none', 'block', 'block'],
          flex: '1 1 auto',
          iframe: {
            w: '100%',
            h: ['267px', '267px', '100%'],
            minH: '267px',
          },
        }}
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3876.0721984924694!2d100.594277!3d13.714077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfe14120eb3d8af4f!2sData%20Wow!5e0!3m2!1sen!2sus!4v1604394161891!5m2!1sen!2sus"
          title="datawow-map"
        ></iframe>
      </Box>
    </Box>
  )
}

const icons = [
  {
    name: 'facebook',
    width: ['14px'],
    height: ['31px'],
    url: externalLinks.FACEBOOK.pathname,
  },
  {
    name: 'linkedin',
    width: ['31px'],
    height: ['31px'],
    url: externalLinks.LINKED_IN.pathname,
  },
  {
    name: 'dw-blog',
    width: ['32px'],
    height: ['32px'],
    url: externalLinks.MEDIUM.pathname,
  },
]

const SocialIcons = () => {
  return (
    <Grid
      sx={{
        gridTemplateColumns: 'repeat(3, max-content)',
        gap: ['30px'],
        justifyContent: 'center',
      }}
    >
      {icons.map((ic) => {
        const { name, width, height, url } = ic

        return (
          <Link
            key={name}
            layerStyle="background"
            sx={{
              backgroundImage: `url(/images/Landing/${name}.svg)`,
              width,
              height,
            }}
            isExternal
            href={url}
          />
        )
      })}
    </Grid>
  )
}

const Products = (): ReactElement => {
  return (
    <Box
      sx={{
        d: ['block', 'block', 'flex', 'flex'],
        flexDir: 'column',
        justifyContent: 'space-between',
        h: ['auto', 'auto', '100%', '100%'],
        maxW: ['100%', '100%', '100%', '543px'],
        mx: '0',
      }}
    >
      <SiteMap />
      <Banner />
    </Box>
  )
}

type ProductItemProps = {
  title: string
  description: string
  url: string
  image: string
}

export const ProductItem = (props: ProductItemProps): ReactElement => {
  const { title, description, url, image } = props

  return (
    <Link
      sx={{
        d: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDir: 'column',
        color: 'white',
        pos: 'relative',
        py: ['12px', '33px'],
        boxShadow: '0 0 0 0 #30ccdb',
        _hover: {
          boxShadow: '4px 4px 0 0 #30ccdb',
        },
        _focus: {
          outline: 'none',
        },
        _active: {
          outline: 'none',
        },
      }}
      href={url}
      isExternal
    >
      <Text sx={{ fontSize: ['20px'], zIndex: 1 }}>{title}</Text>
      <Text sx={{ fontWeight: [300], zIndex: 1 }}>{description}</Text>

      <Image src={`${image}.jpg`} alt={image} layout="fill" objectFit="cover" />
    </Link>
  )
}

const sitemaps = [
  {
    title: 'ทำไมต้อง Learn PDPA',
    pathname: '/',
    href: 'why-us',
  },
  {
    title: 'มั่นใจได้เมื่อเรียนกับเรา',
    pathname: '/',
    href: 'benefits',
  },
  {
    title: 'คอร์สทั้งหมด',
    pathname: 'currentPath',
    href: 'our-courses',
  },
  {
    title: 'PDPA Blog',
    pathname: '/',
    href: 'blogs',
  },
  {
    title: ' สิ่งที่ผู้เรียนจะได้รับ',
    pathname: '/',
    href: 'what-you-will-get',
  },
  {
    title: 'FAQs',
    pathname: '/',
    href: 'faq',
  },
]

const SiteMap = (): ReactElement => {
  return (
    <Grid
      sx={{
        d: ['none', 'none', 'grid', 'grid'],
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'repeat(4, max-content)',
        gap: '24px 85px',
        color: 'white',
        fontSize: '20px',
        w: '100%',
        maxW: '543px',
        mx: 'auto',
      }}
    >
      {sitemaps.map((site) => {
        const { title, href, pathname } = site

        return (
          <Link
            key={title}
            as={ScrollLink}
            url={{
              pathname: pathname,
              hash: href,
            }}
          >
            {title}
          </Link>
        )
      })}
    </Grid>
  )
}

export default Summary
