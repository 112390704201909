import { Box } from '@chakra-ui/react'
import { InView, IntersectionOptions } from 'react-intersection-observer'

const defaultOptions = {
  triggerOnce: true,
}

export interface LazyLoadProps {
  children: React.ReactNode
  options?: IntersectionOptions
}

function LazyLoad({
  children,
  options = defaultOptions,
}: LazyLoadProps): React.ReactElement {
  return (
    <InView {...options}>
      {({ ref, inView }) => {
        return <Box ref={ref}>{inView && children}</Box>
      }}
    </InView>
  )
}

export default LazyLoad
