import { Box, Button, Text, Icon, VStack, Link } from '@chakra-ui/react'
import NextLink from 'next/link'
import { useSpring } from 'react-spring'
import { useInView } from 'react-intersection-observer'

import { AnimatedVStack, DEFAULT_SPRING_CONFIG } from '@/lib/springComponent'
import { logEvent } from '@/lib/stats'

import * as externalLinks from '@/routes/external'

import { ReactComponent as BannerIcon } from '@/svg/contact-banner.svg'

function Banner(): React.ReactElement {
  const { ref, inView } = useInView({
    threshold: 0.4,
    triggerOnce: true,
  })

  const containerSpring = useSpring({
    to: {
      transform: inView
        ? 'perspective(1000px) rotateX(0deg)'
        : 'perspective(1000px) rotateX(-60deg)',
      opacity: inView ? 1 : 0,
    },
    config: {
      tension: 500,
      friction: 40,
      mass: 8,
    },
  })

  const [backgroundSpring, backgroundSpringAPI] = useSpring(() => ({
    backgroundColor: '#F5F5F5FF',
    config: DEFAULT_SPRING_CONFIG,
  }))

  return (
    <Box
      ref={ref}
      sx={{
        w: 'full',
        pt: {
          base: 0,
          sm: 8,
          md: 10,
        },
        maxW: '636px',
        mx: 'auto',
      }}
    >
      <Text
        sx={{
          color: 'white',
          my: '6',
          mb: { base: 8, sm: 6 },
          fontSize: '2xl',
          fontWeight: 'medium',
          textAlign: {
            base: 'center',
            md: 'left',
          },
        }}
      >
        ดำเนินการโดย PDPA Core
      </Text>
      <AnimatedVStack
        spacing="0"
        style={{
          ...containerSpring,
          ...backgroundSpring,
        }}
        sx={{
          transformOrigin: 'top center',
          w: {
            base: 'fit-content',
            sm: 'full',
          },
          maxW: {
            base: 'full',
            md: '929px',
          },
          mx: 'auto',
          mt: 6,
          position: 'relative',
          borderRadius: '2xl',
          p: {
            base: 3,
            sm: 4,
            md: 6,
          },
          minHeight: {
            base: '333px',
            sm: '232px',
            md: '248px',
          },
          alignItems: 'stretch',
          justifyContent: {
            base: 'flex-end',
            sm: 'center',
          },
        }}
      >
        <Icon
          as={BannerIcon}
          sx={{
            position: 'absolute',
            overflow: 'visible',
            top: {
              base: '-8%',
              sm: '50%',
              md: '-15%',
            },
            bottom: {
              base: 'unset',
              sm: 0,
              md: 'unset',
            },
            right: {
              base: '50%',
              sm: '5%',
              md: '5%',
            },
            transform: {
              base: 'translate3d(50%, 0%, 0)',
              sm: 'translate3d(0, -50%, 0)',
              md: 'translate3d(-0%, 0%, 0)',
            },
            h: {
              base: '55%',
              sm: '90%',
              md: '80%',
            },
            w: 'auto',
          }}
        />
        <VStack
          spacing="3"
          sx={{
            position: 'relative',
            w: 'full',
            h: '50%',
            mt: 'auto',
            fontWeight: 'medium',
            textAlign: {
              base: 'center',
              sm: 'left',
            },
            alignItems: 'inherit',
            justifyContent: 'inherit',
          }}
        >
          <Box sx={{ w: { base: 'full', sm: '60%' } }}>
            <Text
              sx={{
                fontSize: {
                  base: 'xl',
                  sm: '3xl',
                  md: '3.5xl',
                },
                color: 'secondary.250',
              }}
            >
              ให้คำปรึกษาและดำเนินการ
            </Text>
            <Text
              sx={{
                fontSize: {
                  base: 'md',
                  sm: '2xl',
                  md: '3xl',
                },
                color: 'secondary.270',
              }}
            >
              ให้ธุรกิจของคุณ สอดคล้องกับ PDPA
            </Text>
          </Box>
          <NextLink passHref href={externalLinks.PDPACORE.pathname}>
            <Button
              as={Link}
              isExternal
              size="lg"
              onMouseEnter={() =>
                backgroundSpringAPI({
                  backgroundColor: '#F5F5F500',
                })
              }
              onMouseLeave={() =>
                backgroundSpringAPI({
                  backgroundColor: '#F5F5F5FF',
                })
              }
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Footer',
                    action: 'Click pdpa core',
                  },
                })
              }}
              sx={{
                minH: 16,
                minW: '240px',
                w: 'full',
                maxW: {
                  base: '358px',
                  md: 'unset',
                },
                colorScheme: 'secondary',
                textDecoration: 'none !important',
                bg: 'secondary.250',
                alignSelf: {
                  base: 'center',
                  sm: 'flex-start',
                },
              }}
            >
              ดูข้อมูลเพิ่มเติม
            </Button>
          </NextLink>
        </VStack>
      </AnimatedVStack>
    </Box>
  )
}

export default Banner
