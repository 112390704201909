export * from './Summary'

import type { SummaryProps } from './Summary'

import dynamic from 'next/dynamic'

import LazyLoad from '@/lib/lazyload'

const DynamicSummary = dynamic(() => {
  return import('./Summary')
})

export const LazySummary = (props: SummaryProps): React.ReactElement => (
  <LazyLoad>
    <DynamicSummary {...props} />
  </LazyLoad>
)
