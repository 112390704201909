import Image from 'next/image'
import Link from 'next/link'
import {
  Text,
  Box,
  LinkBox,
  VStack,
  LinkOverlay,
  AspectRatio,
  Icon,
} from '@chakra-ui/react'

import { courseRoute } from '@/routes'

import { Course } from '@/features/Courses/types'

import { ReactComponent as Foreground } from '@/svg/courses/decorations/course-list-item-foreground.svg'
import { ReactComponent as Background } from '@/svg/courses/decorations/course-list-item-background.svg'

interface Props {
  course: Course
  onClick?: () => void
}

function CourseListItem({ course, onClick }: Props): React.ReactElement {
  return (
    <LinkBox
      layerStyle="actionable"
      sx={{
        display: 'flex',
        alignItems: 'stretch',
        flexFlow: 'column nowrap',
        maxW: '400px',
        overflow: 'visible',
        margin: '15px',
      }}
      height={{
        base: '470px',
        md: '516px',
      }}
      margin={{
        base: '0',
        md: '0',
      }}
    >
      <Icon
        as={Background}
        sx={{
          position: 'absolute',
          left: '-6px',
          bottom: 3,
          h: { base: 9, sm: 10, md: 12 },
          w: 'auto',
          zIndex: -1,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'stretch',
          flexFlow: 'column nowrap',
          boxShadow: '4px 4px 18px rgba(0, 0, 0, 0.12)',
          borderRadius: '12px',
          flex: '1 1 auto',
          bgColor: 'white',
          overflow: 'hidden',
        }}
      >
        <AspectRatio as="figure" ratio={1280 / 720}>
          <Image src={course.image} alt={course.title} layout="fill" />
        </AspectRatio>
        <VStack
          spacing="2"
          sx={{
            alignItems: 'stretch',
            px: { base: 5, md: 8 },
            py: { base: 5, md: 6 },
            flex: '1 1 auto',
          }}
        >
          <Link
            passHref
            href={{
              pathname: courseRoute.pathname,
              query: {
                slug: course.slug,
              },
            }}
          >
            <LinkOverlay onClick={onClick}>
              <Text
                sx={{
                  fontSize: {
                    base: 'lg',
                    sm: 'xl',
                    md: '2xl',
                  },
                  color: 'primary.800',
                  fontWeight: 'bold',
                }}
              >
                {course.title}
              </Text>
              <Icon
                as={Foreground}
                sx={{
                  position: 'absolute',
                  left: '-6px',
                  bottom: 3,
                  h: { base: 9, sm: 10, md: 12 },
                  w: 'auto',
                }}
              />
            </LinkOverlay>
          </Link>
          <Text
            sx={{
              fontSize: {
                base: 'md',
                md: 'lg',
              },
              fontWeight: 'light',
              color: 'gray.700',
            }}
          >
            {course.listItemSubittle || course.subtitle}
          </Text>
          <Box
            sx={{
              mt: 'auto !important',
              alignSelf: 'flex-end',
            }}
          >
            <Text
              sx={{
                mt: 5,
                fontSize: {
                  base: 'lg',
                  sm: 'xl',
                  md: '2xl',
                },
                textAlign: 'right',
                fontWeight: 'bold',
                color: 'primary.400',
                whiteSpace: 'nowrap',
              }}
            >
              {course.price}
            </Text>
          </Box>
        </VStack>
      </Box>
    </LinkBox>
  )
}

export default CourseListItem
